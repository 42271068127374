import $ from 'jquery';

$(() => {
    function toggleNav() {
        $('.nav-pills a').toggleClass('active');
        $('.toggleable').toggleClass('active');
    }
    $('.nav-pills a.month, .nav-pills a.week').on('click', function(e) {
        e.preventDefault();
        toggleNav();
    })
});
