import $ from 'jquery';

$(() => {
  $('#createGlassLink').click((e) => {
    e.preventDefault();
    const project_id = $(e.target).data('project-id');

    $.ajax({ url: '/glass/tokens', method: 'POST', data: {
      project_id: project_id,
      authenticity_token: $('[name="csrf-token"]')[0].content,
    } }).done((data) => {
      $('#glassLinkInput').val(data.link);
      $('#glassModal').modal('show');
    });
  });

  $('#copyGlassLinkButton').click((e) => {
    e.preventDefault();
    navigator.clipboard.writeText($('#glassLinkInput').val());
  });
});
