import $ from 'jquery';
import select from 'tail.select.js';

global.jQuery = $;
require('bootstrap4-toggle');

$(() => {
  const projSelect = document.getElementById('expense_project_id');
  const wrappedSelect = select(projSelect, { search: true, searchMinLength: 1 });
  $('.select-recent-project-expense').click(function (e) {
    e.preventDefault();
    const projectId = $(this).data('project-id');
    wrappedSelect.options.select(String(projectId), '#');
  });
});
