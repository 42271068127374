import $ from 'jquery';

const isVisible = function(ele, container) {
  const { bottom, height, top } = ele.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  return top <= containerRect.top
      ? (containerRect.top - top <= height)
      : (bottom - containerRect.bottom <= height);
};

$(() => {
  setInterval(() => {
    const visible = [];
    $('[data-notification-id].unread').each((e, el) => {
      if(el.offsetParent !== null && isVisible(el, document.getElementById('notificationsContainer'))) {
        visible.push($(el).data('notification-id'));
      }
    });

    if (visible.length > 0) {
      $.post('/notifications/mark_as_read', {
        ids: visible,
      }).then((res) => {
        if (res.success === true) {
          if (res.unread_count === 0) {
            $('#notificationCount').hide();
          } else {
            $('#notificationCount').text(res.unread_count);
          }
        } 
      });
    }

  }, 1000);
});
