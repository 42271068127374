/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'css/site'

require('@rails/ujs').start()
require("chartkick")
require("chart.js")

import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

import '@coreui/coreui/dist/js/coreui.bundle.js'
import 'bootstrap'
import 'bootstrap-datepicker'

import 'app/dashboard'
import 'app/glass'
import 'app/keybindings'
import 'app/tracks'
import 'app/expenses'
import 'app/notifications'
import 'app/invoices'

import '@fortawesome/fontawesome-free/js/all'
