import $ from 'jquery';
import select from 'tail.select.js';

global.jQuery = $;
require('bootstrap4-toggle');

$(() => {
  const trackSelect = document.getElementById('track_selection');
  const wrappedSelect = select(trackSelect, { search: true, searchMinLength: 1 });

  $('.track-selector').on('change', () => {
    wrappedSelect.options.select('custom', '#');
  })

  $(trackSelect).on('change', (e) => {
    if (e.target.value === 'custom') {
      return;
    }
    if (e.target.value === 'all') {
      $('.track-selector').prop('checked', true);
    } else {
      $('.track-selector').prop('checked', false);
      $(`.track-selector[data-year-month="${e.target.value}"]`).prop('checked', true);
    }
  });
});
