import $ from 'jquery';
import Mousetrap from 'mousetrap';


Mousetrap.prototype.stopCallback = (e, element, combo) => {
  // Always allow command + return
  if (combo == 'command+return' || combo == 'ctrl+return') {
    return false;
  }

  if ((' ' + element.className + ' ').indexOf(' mousetrap ') > -1) {
    return false;
  }

  return element.tagName == 'INPUT' || element.tagName == 'SELECT' || element.tagName == 'TEXTAREA' || (element.contentEditable && element.contentEditable == 'true');
};

$(() => {
  // Hotkey binding to links with 'data-keybinding' attribute
  // Navigate link when hotkey pressed
  $('[data-keybinding]').each((i, el) => {
    const bindedKey = $(el).data('keybinding');
    if (typeof (bindedKey) == 'number') {
      bindedKey = bindedKey.toString()
    }
    Mousetrap.bind(bindedKey, (e) => {
      el.click()
    });
  });

  // Hotkey binding to inputs with 'data-keybinding' attribute
  // Focus input when hotkey pressed
  $('input[data-keybinding]').each((i, el) => {
    Mousetrap.bind($(el).data('keybinding'), (e) => {
      el.focus();
      if (e.preventDefault) {
        e.preventDefault();
      } else {
        e.returnValue = false;
      }
    });
  });
});
